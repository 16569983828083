<template>
  <div class="modifyMarginPwd">
    <div class="titles">
      资金密码修改
    </div>
    <div style="background: #F2F3F8;height: 20px;width:100%">

    </div>
    <div style="padding: 15px">
      <div class="modifyMarginPwdTitle">
        <div class="myModifyMarginPwd">
          <p style="font-weight: 700" v-if="isQyBank == 1 ||isQyBank == 13">修改资金密码<span class="tip">&nbsp;&nbsp;&nbsp;&nbsp;温馨提示：密码修改后10分钟后生效</span></p> 
          <p class="tip" v-else @click="goBinkPage">温馨提示：请先去银行签约</p> 
        </div>
      </div>
       <!-- v-if="isQyBank == 1 ||isQyBank == 13" -->
      <div class="modifyMarginPwdContent" v-if="isQyBank == 1 ||isQyBank == 13">
        <el-form ref="form" :model="form" :rules="rules" label-width="100px">
          <el-form-item label="旧密码" prop="oldMarginPwd">
            <el-input v-model="form.oldMarginPwd" :type="oldPwdText">
              <img slot="suffix" :src="closeEye" v-if="PassOldtype" @click="showPwd" />
              <img slot="suffix"  :src="openEye" v-else  @click="hidePwd" />
            </el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="newMarginPwd">
            <el-input v-model="form.newMarginPwd" :type="newPwdText">
              <img slot="suffix" :src="closeEye" v-if="passNewType" @click="showPwd1" />
              <img slot="suffix"  :src="openEye" v-else  @click="hidePwd1" />
            </el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="rePwd">
            <el-input v-model="form.rePwd" :type="newAginPwdText">
              <img slot="suffix" :src="closeEye" v-if="newPwdType" @click="showPwd2" />
              <img slot="suffix"  :src="openEye" v-else  @click="hidePwd2" />
            </el-input>
          </el-form-item>
          <div class="submitButton">
            <el-button type="primary" @click="submitApply()">提交</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import md5 from "js-md5";
export default {
  data() {
    const validatePassCheck = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入确认密码"));
      } else if (value !== this.form.newMarginPwd) {
        callback(new Error("确认密码和新密码不一致"));
      } else {
        callback();
      }
    };
    return {
      form: {
        oldMarginPwd: "",
        newMarginPwd: "",
        rePwd: ""
      },
      rules: {
        oldMarginPwd: [
          { required: true, message: "请输入旧密码", trigger: "blur" }
        ],
        newMarginPwd: [
          { required: true, message: "请输入新密码", trigger: "blur" }
        ],
        rePwd: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          { validator: validatePassCheck, trigger: "blur" }
        ]
      },
      oldPwdText:'password',
      newPwdText:'password',
      newAginPwdText:'password',
      PassOldtype:true,
      passNewType:true,
      newPwdType:true,
      isQyBank:'',
      closeEye:require('../../../assets/images/closeeye.png'),
      openEye:require('../../../assets/images/openeye.png')
    };
  },
  mounted(){
    this.getSignInfo()
  },
  methods: {
    goBinkPage() {
      this.$router.push('/sign')
    },
     showPwd(){
      this.PassOldtype = false
      this.oldPwdText = 'text'
    },
    hidePwd(){
      this.PassOldtype= true
      this.oldPwdText = 'password'
    },
     showPwd1(){
      this.passNewType = false
      this.newPwdText = 'text'
    },
    hidePwd1(){
      this.passNewType= true
      this.newPwdText = 'password'
    },
     showPwd2(){
      this.newPwdType = false
      this.newAginPwdText = 'text'
    },
    hidePwd2(){
      this.newPwdType= true
      this.newAginPwdText = 'password'
    },
    // 签约信息查询
    getSignInfo() {
      http.postFront(protocolFwd.param_queryUserSign).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.isQyBank = value.signFlag
        }else if(Number(code) == -180642){
           this.isQyBank = 0
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    submitApply() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          protocolFwd.param_updateFundPwdEnd.param.oldPwd = md5('47DEQpj8HBSa+/TImW+5JCeuQeRkm5NMpJWZG3hSuFU=' + this.form.oldMarginPwd);
          protocolFwd.param_updateFundPwdEnd.param.newPassWord = md5('47DEQpj8HBSa+/TImW+5JCeuQeRkm5NMpJWZG3hSuFU=' + this.form.newMarginPwd);
          http.postFront(protocolFwd.param_updateFundPwdEnd).then(response => {
            const { code, message, value } = response.data;
            if (code == 0) {
              this.$EL_MESSAGE(message);
              this.$refs['form'].resetFields();
            } else {
              this.$EL_MESSAGE(message);
            }
          });
        }
      });
    }
  }
};
</script>
<style scoped lang="scss">
/deep/ .el-form-item__label {
  text-align: left;
}
.el-input{
  // width: 600px!important;
}

.modifyMarginPwd {
  // padding: 0 15px;
  width: 1200px;
  margin: 0 auto;
  background-color: #fff;
  height: 100%;
  margin-top: 20px;
}
.modifyMarginPwd .myModifyMarginPwd {
  float: left;
  line-height: 40px;
}
.modifyMarginPwdTitle {
  overflow: hidden;
}
.modifyMarginPwdTitle .myModifyMarginPwd p {
  font-size: 16px;
}
.modifyMarginPwdTitle .myModifyMarginPwd .tip {
  font-size: 13px;
  color:#cf4640;
  cursor: pointer;
  font-weight: 700;
}
.modifyMarginPwdContent {
  // width: 80%;
  padding: 15px 330px;
}
.titles {
      padding: 15px;
      font-size: 18px;
  }
  .submitButton {
    text-align: center;
  }
</style>

